/**
 * (c) 2021, Micro:bit Educational Foundation and contributors
 *
 * SPDX-License-Identifier: MIT
 */
.docs-spacing pre,
.docs-spacing code {
  font-family: "Source Code Pro", monospace;
}
.docs-spacing * + * {
  margin-top: var(--chakra-space-2);
}
.docs-spacing * + p {
  margin-top: var(--chakra-space-3);
}
.docs-spacing * + code {
  margin-top: var(--chakra-space-3);
}
.docs-spacing hr + *,
.docs-spacing * + hr {
  margin-top: var(--chakra-space-1);
}
.docs-spacing code {
  white-space: pre-wrap;
}
.docs-spacing ul,
.docs-spacing ol {
  margin-left: var(--chakra-space-5);
}
.docs-spacing a {
  color: var(--chakra-colors-brand-500);
}
.docs-spacing pre {
  padding: var(--chakra-space-1);
}

.docs-code code,
pre {
  background-color: #fff;
  border-radius: var(--chakra-radii-md);
  border-color: var(--chakra-colors-blimpTeal-100);
  border-style: solid;
  border-width: 1px;
}

.docs-code.docs-code-muted code,
pre {
  background-color: #fffb;
}

.docs-code code {
  background-color: #fff;
  padding: 0 var(--chakra-space-1-5);
}

.docs-code pre {
  padding: var(--chakra-space-1-5) 0;
  width: fit-content;
}

.docs-code > pre > code,
.docs-code.docs-code-muted > pre > code {
  display: block;
  border: none;
  background-color: unset;
}

.docs-skip-signature > pre:first-of-type {
  border: none;
  padding: 0;
  background-color: unset;
}
