/**
 * (c) 2021, Micro:bit Educational Foundation and contributors
 *
 * SPDX-License-Identifier: MIT
 */
.xterm-viewport::-webkit-scrollbar {
  background-color: #333333;
}
.xterm-viewport::-webkit-scrollbar-thumb {
  background: var(--chakra-colors-gray-600);
}
.xterm .xterm-viewport {
  scrollbar-color: #333333 var(--chakra-colors-gray-600);
}
